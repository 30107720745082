<script>
import { customerMenu, posMenu } from '../utils/menus'
import Logo from '../assets/images/logo.svg'
import LogoSmall from '../assets/images/logo-small.svg'
import { formatPhoneNumber } from '../utils'
import Icon from './Icon'
import Avatar from './Avatar'
// import EditCustomerModal from '@/components/EditCustomerModal'

export default {
  name: 'ThePosNavBar',

  components: {
    Avatar,
    Icon,
    // EditCustomerModal
  },

  data() {
    return {
      editCustomerModal: false,
    }
  },

  computed: {
    customer() {
      return this.$store.state.tickets.currentCustomer
    },
    services() {
      return this.$store.state.services.list
    },
    logo() {
      return Logo || LogoSmall
    },
    isVisible() {
      return this.$store.state.ui.posMenuVisible && this.customer
    },
    customerMenu() {
      const id = this.customer.id
      return customerMenu(id, this.$store)
    },
    posMenu() {
      return posMenu({ store: this.$store, router: this.$router })
    },
    isPOS() {
      return this.routeIs === 'pos-customer'
    },
    routeIs() {
      return this.$nuxt.$route.name
    },
    userOwes() {
      return this.getOwedAmount(this.customer) > 0
    },
    customerPhone() {
      return this.formatPhoneNumber(this.customer.phone)
    },
  },

  async mounted() {
    if (this.customer && this.customer.id > 0)
      await this.$store.dispatch('services/fetchItems')
  },
  methods: {
    formatPhoneNumber,
    getOwedAmount(customer) {
      return this.$store.getters['tickets/getOwedAmount'](customer)
    },
  },
}
</script>

<template>
  <div
    v-if="isVisible"
    class="h-screen overflow-y-auto font-sans text-xs font-medium bg-white shadow-md text-grey-dark"
    :class="{ hidden: !isVisible }"
  >
    <div class="flex flex-col w-full">
      <div class="flex flex-col bg-grey-lighter">
        <div class="flex flex-col items-center pt-4 font-semibold">
          <Avatar :src="customer.avatar" />
          <span
            class="mt-2 text-base text-black"
            :class="{ 'text-red': userOwes }"
          >
            {{ customer.first_name }} {{ customer.last_name }}
          </span>
          <span class="text-xxs text-grey-dark">ID: 00{{ customer.id }}</span>
          <span class="mt-2 text-black">{{ customerPhone }}</span>
          <!-- <button
            class="w-4 my-3 focus:outline-none"
            @click="editCustomerModal = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="12"
              viewBox="0 0 15 12"
            >
              <path
                fill="#202020"
                fill-opacity=".47"
                fill-rule="evenodd"
                d="M3.15 8.25c.342 0 .898.375 2.1.375 1.212 0 1.755-.375 2.1-.375.391 0 .754.117 1.066.312l.806-.807a3.116 3.116 0 0 0-1.872-.63c-.673 0-.996.375-2.1.375s-1.425-.375-2.1-.375A3.15 3.15 0 0 0 0 10.275v.6C0 11.495.504 12 1.125 12h6.054c-.09-.342-.052-.476.02-1.125H1.126v-.6c0-1.116.91-2.025 2.025-2.025zm1.994-7.123A2.252 2.252 0 0 1 7.497 3.48a2.258 2.258 0 0 1-2.142 2.143 2.252 2.252 0 0 1-2.353-2.354 2.258 2.258 0 0 1 2.142-2.142zm.104 5.623a3.374 3.374 0 0 0 3.377-3.377C8.622 1.537 7.087.003 5.252 0a3.374 3.374 0 0 0-3.377 3.377c.002 1.835 1.537 3.37 3.373 3.373zm9.588-.448l-.889-.888a.562.562 0 0 0-.792 0l-.98.98 1.683 1.68.98-.98a.564.564 0 0 0-.002-.792zm-6.424 3.853l-.16 1.43c-.026.239.176.44.413.412l1.427-.159 3.232-3.232-1.68-1.68-3.232 3.23z"
              />
            </svg>
          </button>
          <edit-customer-modal
            :customer="customer"
            :show="editCustomerModal"
            @close="editCustomerModal = false"
          >
          </edit-customer-modal> -->
        </div>

        <div class="flex flex-col w-full py-3 font-semibold">
          <nuxt-link
            v-for="item in customerMenu"
            :key="item.label"
            :to="item.to"
            active-class="text-white active-menu bg-turquoise"
            class="w-full px-5 py-3 no-underline transition duration-150 hover:bg-turquoise hover:text-white"
          >
            <Icon :icon="item.icon" class="mr-2 text-base" /> {{ item.label }}
          </nuxt-link>
        </div>
      </div>
      <div class="py-4 pl-3 text-xs">
        <span class="px-2 text-turquoise">
          <i class="fa fa-arrow-down" />
        </span>
        <span class="text-black">Create New Ticket</span>
      </div>

      <a
        v-for="item in posMenu"
        :key="item.service"
        href="javascript:void(0)"
        class="flex items-center px-5 py-3 font-semibold no-underline transition duration-150 focus:outline-none hover:bg-turquoise hover:text-white"
        :class="{
          'bg-turquoise text-white': item.active && isPOS,
        }"
        @click.prevent="item.action"
      >
        <Icon :icon="item.icon" class="mr-2 text-xl" />
        {{ item.label }}
      </a>
    </div>
  </div>
</template>

<style scoped>
.active_menu Icon {
  color: white;
}
</style>
